// normalize CSS across browsers
import './src/reset.css';
import 'rc-slider/assets/index.css';
// custom CSS styles
import './src/custom.css';

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}